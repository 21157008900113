import * as React from "react"

import Content from "../components/content"

export default (): JSX.Element => {
	const [content, setContent] = React.useState(null)
	React.useEffect(() => {
		fetch(`${process.env.BASEPATH ?? ""}/api/content`)
			.then((nonJsonContent) => nonJsonContent.json())
			.then((data) => setContent(data))
	}, [])

	return <>{content && <Content data={content.content} />}</>
}
